import React from "react";
import ReactTable from "react-table";
import { Card } from "reactstrap";
import { BoxLoading } from "react-loadingg";
import CustomPagingTag from "./CustomPagingTag";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";

import { format } from "date-fns";
import { enUS } from "date-fns/locale";
const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Index = ({
  startIndex = 1,
  paging,
  setPaging,
  data,
  columns = [],
  pageSize = 20,
  disablePagination = false,
  style = {},
}) => {
  const { totalElements, totalPages } = data || {};
  const isRowEmpty = (row, startIndex) => {
    const values = Object.values(row).slice(startIndex);
    return Object.values(values).every(value => value === null || value === "");
  };
  const filteredData = data?.data?.filter(row => !isRowEmpty(row, startIndex));
  
  // const isColumnEmpty = column => {
  //   return filteredData?.every(row => {
  //     return row[column.accessor] === null;
  //   });
  // };

  // // Filter columns that are not empty
  // const visibleColumns = columns.filter(column => !isColumnEmpty(column));
  return (
    <Card>
      <ReactTableFixedColumns
        data={filteredData}
        columns={columns}
        style={{ height: "calc(100vh - 350px)", ...style }}
        showPagination={false}
        loading={!data}
        loadingText={<BoxLoading />}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        showPaginationBottom={!disablePagination}
        filterable={false}
        sortable={false}
        minRows={
          filteredData?.length > 20 ? (filteredData?.length >= pageSize ? pageSize : filteredData?.length) : 20
        }
      />
      {!disablePagination ? (
        <CustomPagingTag
          page={paging.currentPage}
          pages={totalPages || 0}
          pageSize={paging.pageSize || pageSize}
          previousText="Previous"
          nextText="Next"
          onPageChange={val =>
            setPaging(prev => ({ ...prev, currentPage: val }))
          }
          totalRecords={totalElements || 0}
          onPageSizeChange={val =>
            setPaging(prev => ({ currentPage: 1, pageSize: val }))
          }
          loading={!data}
        />
      ) : null}
    </Card>
  );
};

export const scrollToTopRow = () => {
  try {
    const table = document.getElementsByClassName("ReactTable")[0];
    table.getElementsByClassName("rt-table")[0].scrollTo(0, 0);
  } catch (err) {
    console.log("Can not scroll to top:", err);
  }
};

export const getSeqByCellData = cellData => {
  return (
    (cellData.page ? cellData.page - 1 : cellData.page) * cellData.pageSize +
    cellData.viewIndex +
    1
  );
};

export const getSTTCellData = (cellData, paging) => {
  return (
    (paging?.currentPage ? paging?.currentPage - 1 : paging?.currentPage) *
      paging?.pageSize +
    cellData.viewIndex +
    1
  );
};

export const formatValueToMoneyVND = value => {
  return value
    ? value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
      })
    : "";
};

export const formatValueToMonthYear = value => {
  return value ? format(new Date(value), "yyyy-MM", { locale: enUS }) : "";
};

export const formatNumber = number => {
  const numStr = (number || 0).toString();
  const [_, decimal] = numStr.split(".");

  // Trả về chuỗi đã được format
  if (decimal) {
    return (number || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return numStr.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
};
export default Index;
